'use client'

import { useContext, useEffect } from 'react'
import { Divider, Spacer } from '@vinted/web-ui'

import { ItemDto, ItemPageSellerBadgesInfoDto } from 'types/dtos'
import Advertisement from 'components/Advertisement'

import InfoBanner from 'components/InfoBanner'
import SeparatedList from 'components/SeparatedList'
import { AdShape } from 'constants/ads'
import { GoogleTagManagerEvent } from 'constants/google'
import useGoogleTagManagerTrack from 'hooks/useGoogleTagManagerTrack'
import useSession from 'hooks/useSession'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

import DetailsSection from './DetailsSection'
import { useItemPlugins } from '../hooks'
import ItemPagePerformanceBlockPlugin from '../plugins/PerformanceBlock'
import ItemPageOfflineVerificationStatusPlugin from '../plugins/OfflineVerificationStatus'
import ItemPageElectronicsVerificationStatusPlugin from '../plugins/ElectronicsVerificationStatus'
import ItemPageItemStatusPlugin from '../plugins/ItemStatus'
import ItemPageLegalDpoFootnotePlugin from '../plugins/LegalDpoFootnote'
import ItemPageTransparencyLawPlugin from '../plugins/TransparencyLaw'
import ItemPageUserInfoHeaderPlugin from '../plugins/UserInfoHeader'
import ItemPageUserInfoSellerBadgesPlugin from '../plugins/UserInfoSellerBadges'
import ItemPageUserInfoDetailsPlugin from '../plugins/UserInfoDetails'
import ItemPageUserInfoActionsPlugin from '../plugins/UserInfoActions'
import { PluginsContext } from '../containers/PluginsProvider'
import { PluginName } from '../types'
import Plugin from '../plugins/Plugin'

type Props = {
  item: ItemDto
}

const Sidebar = ({ item }: Props) => {
  const { screen: screenName } = useSession()
  const { googleTagManagerTrack } = useGoogleTagManagerTrack()
  const isFastReplierBadgeEnabled = useFeatureSwitch('web_fast_replier_badge')
  const plugins = useItemPlugins(item)
  const { plugins: serverSidePlugins } = useContext(PluginsContext)

  useEffect(() => {
    const value = parseFloat(item.price.amount)
    const price = parseFloat(item.discount_price?.amount || item.price.amount)
    const discount = Math.round((value - price) * 100) / 100
    googleTagManagerTrack(GoogleTagManagerEvent.ItemView, {
      item_name: item.title,
      item_id: item.id,
      value,
      price,
      discount,
      currency: item.currency,
      user_email: item.user.email,
      item_category_id: item.catalog_id,
      item_brand: item.brand_dto?.title,
      item_brand_id: item.brand_dto?.id,
      item_condition: item.disposal_conditions,
    })
  }, [googleTagManagerTrack, item])

  // will remove this function after server side plugin ab test is scaled - for now this seems cleaner this way
  const renderSellerBadgesPlugin = () => {
    const serverSideSellerBadgesPlugin = serverSidePlugins?.find(
      plugin => plugin.name === PluginName.SellerBadgesInfo,
    )

    if (
      isFastReplierBadgeEnabled &&
      serverSideSellerBadgesPlugin &&
      (serverSideSellerBadgesPlugin?.data as ItemPageSellerBadgesInfoDto).badges.length
    ) {
      return <Plugin data={serverSideSellerBadgesPlugin} />
    }

    if (!plugins?.userInfoSellerBadges?.seller_badges?.length) return null

    return <ItemPageUserInfoSellerBadgesPlugin data={plugins.userInfoSellerBadges} />
  }

  return (
    <>
      <section>
        <InfoBanner screen={screenName} />
      </section>
      <ItemPagePerformanceBlockPlugin
        performanceBlockPluginModel={plugins?.performanceBlock ?? undefined}
      />
      <ItemPageItemStatusPlugin data={plugins?.itemStatus ?? undefined} />
      {plugins?.offlineVerificationStatus && (
        <ItemPageOfflineVerificationStatusPlugin data={plugins.offlineVerificationStatus} />
      )}
      <ItemPageElectronicsVerificationStatusPlugin />
      <div
        className="box box--item-details u-border-radius-inherit"
        data-testid="item-sidebar-details-section-container"
      >
        <DetailsSection item={item} data={plugins} />
      </div>
      <Spacer size={Spacer.Size.Large} />
      <SeparatedList separator={<Divider />}>
        {plugins?.userInfoHeader && <ItemPageUserInfoHeaderPlugin data={plugins.userInfoHeader} />}
        {renderSellerBadgesPlugin()}
        {plugins?.userInfoDetails && (
          <ItemPageUserInfoDetailsPlugin data={plugins.userInfoDetails} />
        )}
        {plugins?.userInfoActions && (
          <ItemPageUserInfoActionsPlugin data={plugins.userInfoActions} />
        )}
      </SeparatedList>
      <ItemPageLegalDpoFootnotePlugin data={plugins?.legalDpoFootnote ?? undefined} />
      <ItemPageTransparencyLawPlugin data={plugins?.transparencyLaw ?? undefined} />
      <Advertisement shape={AdShape.Rectangle} id="ad-rectangle" isSidebarAd />
      <Advertisement shape={AdShape.Skyscraper} id="ad-skyscraper" isSidebarAd />
    </>
  )
}

export default Sidebar
