import {
  HorizontalScrollBlockItemDto,
  ItemPageHorizontalScrollBlockDto,
  ItemPageHorizontalScrollBlockHeaderDto,
} from 'types/dtos'
import { getItemThumbnail } from 'data/utils/item'
import { ItemThumbnailSize } from 'constants/images'
import { transformItemPhotoDtos } from 'data/transformers/item'
import {
  getDominantColor,
  transformPartialUserDtoToProductItemUser,
} from 'data/transformers/product-item'
import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'
import { transformIconBadges } from 'data/transformers/icon-badges'
import { transformItemBoxDto } from 'data/transformers/item-box'

import {
  HorizontalScrollBlockItemModel,
  ItemPageHorizontalScrollBlockHeaderPluginModel,
  ItemPageHorizontalScrollBlockPluginModel,
} from './types'

export const transformHorizontalScrollBlockItemDtoToProductItem = (
  item: HorizontalScrollBlockItemDto,
): HorizontalScrollBlockItemModel => ({
  id: item.id,
  title: item.title,
  url: item.url,
  photos: item.photo ? transformItemPhotoDtos([item.photo]) : [],
  viewCount: item.view_count,
  favouriteCount: item.favourite_count,
  brandTitle: item.brand_title || '',
  sizeTitle: item.size_title || '',
  user: transformPartialUserDtoToProductItemUser(item.user),
  price: transformCurrencyAmountDto(item.price),
  thumbnailUrl: item.photo ? getItemThumbnail([item.photo], ItemThumbnailSize.Large) : null,
  dominantColor: item.photo ? getDominantColor([item.photo]) : null,
  badge: item.badge,
  isPromoted: item.promoted,
  isDraft: item.is_draft,
  isFavourite: item.is_favourite,
  iconBadges: transformIconBadges(item.icon_badges),
  searchTrackingParams: item.search_tracking_params,
  priceWithDiscount: item.discount_price ? transformCurrencyAmountDto(item.discount_price) : null,
  status: item.status,
  serviceFee: item.service_fee ? transformCurrencyAmountDto(item.service_fee) : null,
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDto(item.total_item_price)
    : undefined,
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
})

export const getItemPageHorizontalScrollBlockPluginModel = (
  pluginData: ItemPageHorizontalScrollBlockDto,
): ItemPageHorizontalScrollBlockPluginModel => ({
  itemId: pluginData.item_id,
  contentSource: pluginData.content_source,
  items: pluginData.items
    ? pluginData.items.map(transformHorizontalScrollBlockItemDtoToProductItem)
    : null,
})

export const getItemPageHorizontalScrollBlockHeaderPluginModel = (
  pluginData: ItemPageHorizontalScrollBlockHeaderDto,
): ItemPageHorizontalScrollBlockHeaderPluginModel => {
  const {
    header_cta: headerCta,
    item_id: itemId,
    title,
    content_source: contentSource,
  } = pluginData

  return {
    itemId,
    title,
    contentSource,
    headerCta: headerCta ? { title: headerCta.title, url: headerCta.url } : null,
  }
}
